const toTwoDecimalString = (value: number) => {
	return value.toFixed(2)
}

/**
 * Converts a number or string to a currency string.
 * @param price - The price to convert to a currency string.
 * @returns The price as a currency string.
 * @example
 * toCurrencyString(1234.56) // '$1,234.56'
 * toCurrencyString('1234.56') // '$1,234.56'
 * toCurrencyString('$1234.56') // '$1,234.56'
 * toCurrencyString('1234.56 ') // '$1,234.56'
 * toCurrencyString(-1234.56) // '$-1,234.56'
 * toCurrencyString(null) // '$0.00'
 * toCurrencyString(undefined) // '$0.00'
 * toCurrencyString('') // '$0.00'
 * toCurrencyString('abc') // '$0.00'
 * toCurrencyString(NaN) // '$0.00'
 *
 */
const toCurrencyString = (price: string | number | undefined | null): string => {
	let numericPrice = 0

	if (typeof price === 'number') {
		numericPrice = price
	} else if (typeof price === 'string') {
		// Remove whitespace and '$' characters then parse
		const cleanedPrice = price.trim().replace(/\$/g, '')
		numericPrice = parseFloat(cleanedPrice)
		// If the result is not a valid number, default to 0
		if (isNaN(numericPrice)) {
			numericPrice = 0
		}
	}

	// price is null or undefined: numericPrice stays 0

	const usdFormatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	})

	return usdFormatter.format(numericPrice)
}

export { toTwoDecimalString, toCurrencyString }
